<template>
<!-- 基础信息学校管理 不显示 -->
  <div>
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.schoolName" clearable placeholder="请输入学校名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
      </el-form-item>
    </el-form>
    <paged-list v-model="queryParams" :data="pageData" :total="10">
      <template v-slot="scope">
        <div class="box-style-a">
          <div class="title">{{ scope.row.name }}</div>
          <div class="status">已维护</div>
          <div class="content cfx">
            <ul>
              <li><span>教育层次</span> 本科</li>
              <li><span>标识码</span> 5678999</li>
              <li><span>主管部门</span> 某教育部门</li>
              <li><span>所属地区</span> 安徽省-合肥市-高新区</li>
            </ul>
          </div>
          <ul class="other cfx">
            <li><span>CEO</span>谭承</li>
            <li><span>手机号码</span>19276683325</li>
          </ul>
        </div>
      </template>
      <template v-slot:control>
        <el-button type="primary" icon="el-icon-edit" circle></el-button>
        <el-button type="danger" icon="el-icon-delete" circle></el-button>
      </template>
    </paged-list>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'schoolManagement',
  components: {
    PagedList
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        schoolName:''
      },
      pageData:[
        { name:'中国科技大学' }
      ],
      pageApi:'membersSchoolList',
    };
  },
  computed: {
    //...mapState("basicSchool", []),
  },
  methods: {
    //...mapActions("basicSchool",[]),

  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))

    //this.refresh()
  }
};
</script>