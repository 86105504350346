<template>

    <div class="paged-page">
        <el-row :gutter="20">
            <el-col :span="span" v-for="(item,index) in data" :key="index" style="margin-top:20px;">
                <div class="box rel btn">
                    <slot v-bind:row="item"></slot>
                    <div class="mask page">
                        <slot name="control" v-bind:row="item"></slot>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-pagination
            background
            v-if="!hidePagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
export default {
  name: "PagedTable",
  components: {},
  props: {
    data: Array,
    loading: {
      type: Boolean,
      default: false
    },
    span: {
      type: Number,
      default: 6
    },
    total: {
      type: Number,
      default: 0
    },
    value: null,
    hidePagination: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      }
    };
  },
  watch: {
    value() {
      this.onValueChange();
    },
  },
  methods: {
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.$emit("input", this.queryParams);
      this.$emit("refresh");
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.$emit("input", this.queryParams);
      this.$emit("refresh");
    },
    onValueChange() {
      if (this.value !== null && this.value !== undefined)
        this.queryParams = Object.assign(this.value, this.queryParams);
    }
  },
  mounted() {
    this.onValueChange()
    this.$emit("input", this.queryParams);
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/theme.scss";
.paged-page {
    .mask { padding:20px; background:rgba(0,0,0,0.1); display:none; text-align:right; }
    .el-col {
        &:hover .mask { display:block; }
    }
}
</style>